import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar component
import styled from 'styled-components';
import './styles.css';

const LearnMore = () => {
  return (
    <div className="main-container">
      <Container fluid className="styled-container text-black p-4 border border-black border-5 mt-5">
        <CustomNavbar /> {/* Add the CustomNavbar here */}
        
        <StyledContainer fluid style={{ backgroundColor: 'rgba(255, 255, 255, 0.7)' }} className="text-black p-4 border border-black border-5 mt-5">
          <h1>NICHOLAS SKEBA</h1>
          <Row className="align-items-center mb-4">
            <Col md={3}>
              <img style={{ width: 140, borderRadius: 100 }} src="path-to-your-image/profile.png" alt="Nicholas Skeba"/>
            </Col>
            <Col md={9}>
              <p>
                Born and raised in Grand Rapids, Michigan, I am a dynamic professional with a unique blend of skills and interests, particularly in the fields of marketing and program management. My MBA from ESMT Berlin has provided me with a solid foundation in business principles and practices, enabling me to excel in diverse professional environments.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col>
              <p>nicholas.skeba@mba2019.esmt.org • <a href="https://www.linkedin.com/in/nicholas-skeba/" target="_blank" rel="noopener noreferrer">linkedin.com/in/nicholas-skeba</a> • <a href="https://skeba.info" target="_blank" rel="noopener noreferrer">https://skeba.info</a></p>
              
              <h2>CERTIFICATIONS</h2>
              <StyledList>
                <li>Tableau</li>
                <li>Google Ads Display</li>
                <li>Google Analytics</li>
                <li>Hootsuite</li>
                <li>Professional Scrum Product Owner (PSPO)</li>
                <li>SEMrush SEO</li>
                <li>UX (General Assembly)</li>
                <li>Amazon Ads</li>
                <li>HubSpot</li>
                <li>SQL</li>
                <li>Python (In Progress)</li>
              </StyledList>
              
              <h2>EXPERIENCE</h2>
              
              <StyledExperience>
                <h3>Lifecycle Marketing Manager</h3>
                <p>Parallels GmbH | Seattle, WA | Aug 2020 – Current</p>
                <StyledList>
                  <li>Developed and exceeded marketing channel goals by over 10% through targeted customer segmentation.</li>
                  <li>A/B tested campaigns and improved in-product messaging, email, and website layouts in order to improve overall product acquisition that contributed to 2020 Q4 sales goals of $2 million over the targeted revenue.</li>
                  <li>Drove new customer acquisition through creation of customer trial survey campaign that added additional $30k in sales within 2 months of launch.</li>
                  <li>Devised campaign with 10%+ CTR in converting customers from perpetual to SaaS subscription, increasing LTV 20%.</li>
                  <li>Managed localization, creative, and marketing teams to achieve 2-week targeted implementation timeline.</li>
                </StyledList>
              </StyledExperience>
              
              <StyledExperience>
                <h3>Customer Acquisition and Retention Internship</h3>
                <p>DiscoEat GmbH | Berlin, Germany | Oct 2019 – Dec 2019</p>
                <StyledList>
                  <li>Led a five-member team, coordinating tasks within Asana to analyze syndicated customer data, perfecting the customer journey from sign up to referral, which led to 30% referral increase after retooling end to end referral process.</li>
                  <li>Spearheaded weekly strategy meetings, discussing product and brand ambassador campaign to executive stakeholders (CEO, marketing lead), defining referral strategy based on positioning, pricing, and operation cost.</li>
                  <li>Utilized data analytics to extract insights and optimize customer acquisition strategy with the effect of improving ROI.</li>
                </StyledList>
              </StyledExperience>
              
              <StyledExperience>
                <h3>SaaS Performance Marketing and Growth Strategy</h3>
                <p>CostBrain Llc | Chicago, IL | Sept 2015 – Dec 2018</p>
                <StyledList>
                  <li>Increased organic traffic +200% by conducting a complete technical content SEO audit and content strategy.</li>
                  <li>Scaled paid search campaigns, maximizing ROAS by maintaining acquisition efficiency.</li>
                  <li>Interviewed 50 potential users, analyzing results with team to define target audience for SaaS product, defining optimal channels to understand competitive landscape and target persona needs.</li>
                </StyledList>
              </StyledExperience>
              
              <StyledExperience>
                <h3>SEM / SEO Campaign Manager</h3>
                <p>Skeba Digital Corp | Chicago, IL | Sep 2008 – Dec 2018</p>
                <StyledList>
                  <li>Improved CTR 50% monitoring digital marketing assets within Google Analytics (email, web banner, social media, web page) using A/B testing to refine layouts and determine highest performing campaigns.</li>
                  <li>Onboarded, trained, and oversaw 3 copywriters to implement content strategy formulated from Analytics analysis.</li>
                  <li>Owned new customer growth, amplifying site traffic from 20k users per month to 500k users by directing team to compose content in WordPress CMS.</li>
                </StyledList>
              </StyledExperience>
              
              <h2>EDUCATION</h2>
              <StyledEducation>
                <h3>Master of Business Administration</h3>
                <p>European School of Management and Technology | Berlin, Germany | Dec 2019</p>
              </StyledEducation>
              
              <StyledEducation>
                <h3>Consumer Behavior and Theory MBA Module</h3>
                <p>Yale School of Management | New Haven, CT | Oct 2019</p>
              </StyledEducation>
              
              <StyledEducation>
                <h3>Bachelor of Business Administration</h3>
                <p>Western Michigan University | Kalamazoo, MI | May 2007</p>
              </StyledEducation>
              
              <h2>SKILLS</h2>
              <StyledSkills>
                <p><strong>Languages:</strong> English: Native • Spanish: Advanced (B2) • German: Advanced (B2)</p>
                <p><strong>Design:</strong> HTML • CSS • JavaScript • Adobe Creative Suite (Photoshop, Illustrator)</p>
                <p><strong>Marketing:</strong> SEO • CRM • Ahrefs • SEM • Ad Platforms (Facebook, Bing, Twitter, Google AdWords)</p>
                <p><strong>Tools:</strong> Jira • Asana • Microsoft Office (PowerPoint, Word, Excel, Teams) • Google Webmaster • SQL</p>
              </StyledSkills>
            </Col>
          </Row>
        </StyledContainer>
      </Container>
    </div>
  );
};

const StyledContainer = styled(Container)`
  max-width: 900px;
  margin: 0 auto;
`;

const StyledList = styled.ul`
  margin-bottom: 20px;
  padding-left: 20px;
  list-style-type: disc;
`;

const StyledExperience = styled.div`
  margin-bottom: 30px;
  h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
  }
`;

const StyledEducation = styled.div`
  margin-bottom: 20px;
  h3 {
    font-weight: bold;
    margin-bottom: 5px;
  }
  p {
    margin-bottom: 10px;
  }
`;

const StyledSkills = styled.div`
  p {
    margin-bottom: 10px;
    strong {
      font-weight: bold;
    }
  }
`;

export default LearnMore;
