import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import SoundCloudWidgetPlayer from './SoundCloudWidgetPlayer'; // Import SoundCloudWidgetPlayer
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Media = () => {
  return (
    <div className="main-container">
      <Container fluid className="styled-container text-white p-4 border border-white border-5 mt-5">
        <CustomNavbar />
        
        {/* Music Production Section */}
        <Card className="styled-card text-white mt-4">
          <Card.Body>
            <Row className="align-items-center">
              <Col md={6}>
                <h2>Music Production</h2>
                <p>Music experimentation using Ableton</p>
                <a href="https://soundcloud.com/datenflux" target="_blank" rel="noopener noreferrer">
                  <button className="styled-button">
                    <span className="left"></span>
                    <span className="right">SoundCloud</span>
                  </button>
                </a>
              </Col>
              <Col md={6}>
                <div className="soundcloud-player-wrapper">
                  <SoundCloudWidgetPlayer
                    url="https://soundcloud.com/datenflux"
                    width="100%"
                    height="166"
                    options={{
                      auto_play: false,
                      hide_related: false,
                      show_comments: true,
                      show_user: true,
                      show_reposts: false,
                      visual: true,
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>

        {/* YouTube Playlist Embed Section */}
        <Card className="styled-card text-white mt-4">
          <Card.Body>
            <h2>Latest Videos</h2>
            <Row>
              <Col md={12}>
                <div className="youtube-video-wrapper">
                  <iframe 
                    width="100%" 
                    height="400" 
                    src="https://www.youtube.com/embed/videoseries?list=PLpJ15zjklCYRehyp2kY2DHGkt9nGAVyBn" 
                    frameBorder="0" 
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                    allowFullScreen
                    title="YouTube Playlist">
                  </iframe>
                </div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Container>
    </div>
  );
};

export default Media;
