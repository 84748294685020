import React from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import CustomNavbar from './Navbar'; // Import the CustomNavbar
import './styles.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@fortawesome/fontawesome-free/css/all.min.css';

const Portfolio = () => {
  return (
    <div className="main-container">
      <Container fluid className="styled-container text-white p-4 border border-white border-5 mt-5">
        <CustomNavbar />

        {/* Mobile Apps Section */}
        <Row className="mb-4">
          <Col md={12}>
            <h2 className="section-title">Mobile Apps</h2>
            <Row>
              <Col md={4} className="mb-4">
                <Card className="styled-image-card text-white">
                  <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/appimage1.png)' }}></div>
                  <Card.Body className="card-content">
                    <Card.Title>Task Pads - AI Task Manager</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="styled-image-card text-white">
                  <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/appimage2.png)' }}></div>
                  <Card.Body className="card-content">
                    <Card.Title>Big Burger Co</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4} className="mb-4">
                <Card className="styled-image-card text-white">
                  <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/appimage3.png)' }}></div>
                  <Card.Body className="card-content">
                    <Card.Title>CurbAware - Free Curbside App</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Web Development Section */}
        <Row>
          <Col md={12}>
            <h2 className="section-title">Web Development</h2>
            <Row>
              <Col md={4} className="mb-4">
                <Card className="styled-image-card text-white">
                  <div className="image-container" style={{ backgroundImage: 'url(path-to-your-image/webimage1.png)' }}></div>
                  <Card.Body className="card-content">
                    <Card.Title>Costbrain - Restaurant food costing software</Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Portfolio;
